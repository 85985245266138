<template>
  <div class="profile-menu">
    <div class="login">
      <span class="name">{{ $auth.user().first_name }}</span>
      <span class="role">{{ role }}</span>
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('user')"
      @mouseenter="user='user_hover'"
      @mouseleave="user='user'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="user"
      />
      {{ $t("profileMenu.profile") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('security')"
      @mouseenter="shield='shield_hover'"
      @mouseleave="shield='shield'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="shield"
      />
      {{ $t("profileMenu.security") }}
    </div>

    <div
      class="profile-menu__item"
      @click="openPage('settings')"
      @mouseenter="gear='gear_hover'"
      @mouseleave="gear='gear'"
    >
      <AuIcon
        class="profile-menu__image"
        :icon="gear"
      />
      {{ $t("profileMenu.settings") }}
    </div>

    <div class="logout">
      <div
        class="profile-menu__item "
        @click="goToLogout"
        @mouseenter="logout='logout_hover'"
        @mouseleave="logout='logout'"
      >
        <AuIcon
          class="profile-menu__image"
          :icon="logout"
        />
        {{ $t("profileMenu.logout") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "user",
      shield: "shield",
      gear: "gear",
      logout: "logout"
    };
  },
  computed: {
    role() {
      const str = this.$auth.user().type.toUpperCase()[0] + this.$auth.user().type.slice(1);
      return str == "Guest" ? "Investor" : str;
    }
  },
  methods: {
    goToLogout() {
      this.$router.push("/logout");
    },

    openPage(name) {
      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu
{
  width: 245px;
  background-color: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  z-index: 2500;

  &__item {
    font-size: 15px;
    color: var(--color-60);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
    padding-bottom: 17px;

    &:hover {
      color: var(--text-color);
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__image {
    width: 20px;
    height: 20px;
  }

  &__profile {
    font-size: 13px;
  }
}

.logout {
  padding: 15px 0;
  border-top: 1px solid var(--color-30);
  cursor: pointer;
}
.login {
  display: none;
  padding-bottom: 15px;
  margin-bottom: 17px;
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .role {
    font-weight: 400;
    font-size: 12px;
    color: var(--color-50);
  }
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-60);
  }
}
</style>